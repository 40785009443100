<template>
  <v-container id="dashboard" fluid tag="section">

    <v-row>

      <v-col cols="12" md="6">
        <div class="card mb-3 shadow-sm" style="border-radius:0px; height: 100%;">
          <div class="card-header">
              <div class="subtitle-1 font-weight-light" >Résultat du challenge retard progréssif</div>
          </div>
          <div class="card-body text-primary">
            <apexchart type="area"  height="400px" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
      </v-col>

       <v-col cols="12" md="6">
        <div class="card mb-3 shadow-sm" style="border-radius:0px; height: 100%;">
          <div class="card-header">
              <div class="subtitle-1 font-weight-light" > Actualité de l'entreprise</div>
          </div>
          <div class="card-body m-0 p-1" style="border-radius:0px; height: 100%;">
             <el-carousel trigger="click" height="500px">
              <el-carousel-item v-for="item in actu_URl" :key="item">
                <img :src="item" alt="" width="100%" height="100%">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
  

      <v-col
        cols="12"
        md="12"
      >
        <base-material-card
          color="primary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Mon équipe
            </div>

            <div class="subtitle-1 font-weight-light">
              Liste des collaborateurs de cervo
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import NProgress from 'nprogress';
    import VueApexCharts from 'vue-apexcharts';

    export default {

      name: 'DashboardDashboard',

      components: {
        apexchart: VueApexCharts,
      },

      data () {
        return {

          actu_URl : [
              'https://xperf-api.xjoel.com/pub/1Mai%202.jpg',
              'https://xperf-api.xjoel.com/pub/Blue%20Festive%20Happy%20Ramadan%20Instagram%20Post.jpg',
              'https://xperf-api.xjoel.com/pub/perla.png'
          ],

          series: [{
            name: 'Akwa',
            data: [16, 35, 0, 136, 40]
          }, 
          {
            name: 'Ndokoti',
            data: [37, 0, 188, 46, 0]
          },
          {
            name: 'Bepanda',
            data: [0, 0, 0, 0, 0]
          },
          {
            name: 'Mimboman',
            data: [0, 0, 0, 0, 0]
          },
          {
            name: 'Bafoussam',
            data: [38, 0, 0, 0, 84]
          },
          {
            name: 'Nlongkak',
            data: [4, 7, 0, 23, 0]
          }
          ],
          chartOptions: {
            chart: {
              // height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'date',
              categories: ["2023-05-02", "2023-05-03", "2023-05-04", "2023-05-05", "2023-05-06"]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
          
          headers: [
            {
              sortable: false,
              text: 'Nom',
              value: 'nom',
            },
            {
              sortable: false,
              text: 'Prenom',
              value: 'prenom',
            },
            {
              sortable: false,
              text: 'Email',
              value: 'email',
            },
            {
              sortable: false,
              text: 'Contact',
              value: 'contact',
            },
            {
              sortable: false,
              text: 'Ville',
              value: 'ville',
            },
          ],
          items: [],
          tabs: 0,
          tasks: {
            0: [
              {
                text: 'Sign contract for "What are conference organizers afraid of?"',
                montant: 200000,
                value: true,
              },
              {
                text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
                montant: 200000,
                value: false,
              },
              {
                text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
                montant: 200000,
                value: false,
              },
              {
                text: 'Create 4 Invisible User Experiences you Never Knew About',
                montant: 200000,
                value: true,
              },
            ],
            1: [
              {
                text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
                montant: 200000,
                value: true,
              },
              {
                text: 'Sign contract for "What are conference organizers afraid of?"',
                montant: 200000,
                value: false,
              },
            ],
            2: [
              {
                text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
                montant: 200000,
                value: false,
              },
              {
                text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
                montant: 200000,
                value: true,
              },
              {
                text: 'Sign contract for "What are conference organizers afraid of?"',
                montant: 200000,
                value: true,
              },
            ],
          },
          list: {
            0: false,
            1: false,
            2: false,
          },
        }
      },
      created(){
        this.loadData();
      },
      methods: {

        complete (index) {
          this.list[index] = !this.list[index]
        },

        async loadData() {

                NProgress.start();
                // try {

                    const response = await axios.get('xperf/AcceuilColab');
                    var res = response.data.data_T_colab;
                    
                    console.log(res);

                    res.forEach(element => {

                      let crm = {
                        nom : element.name,
                        prenom: element.surname,
                        email: element.email,
                        contact: element.phone,
                        ville: element.ville,
                      };

                      this.items.push(crm);
                      
                    });

                // } 
                // catch (error) {
                //         this.$notify({
                //         type: 'error',
                //         title: "Echec de recuperation des informations "
                //     });
                // }

                NProgress.done();
        },

      },


    }
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>